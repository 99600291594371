import React from 'react'
import styles from "../styles/styles.module.scss"

function LinkImage({imageName, link, headerText, ...props}){

    return (
        <div className={styles.container} {...props}>
            <h3 className={styles.headerText}>{headerText}</h3>

            <a href={link} target="_blank" rel="noreferrer" className={styles.image_link}>
                <img src={`/${imageName}`} alt={imageName} className={styles.image}/>
            </a>
        </div>
    )
}

export default LinkImage
