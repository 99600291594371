export default function getContent(t){
    const FormBlock = {
        HeaderText: t("FormBlock.HeaderText"),
        EmailPlaceholder: t("FormBlock.EmailPlaceholder"),
        MessagePlaceholder: t("FormBlock.MessagePlaceholder"),
        NamePlaceholder: t("FormBlock.NamePlaceholder"),
        Button: t("FormBlock.Button"),
    }

    const SurveyBlock = {
        HeaderText: t("SurveyBlockHeaderText"),
        QR: t("SurveyBlockQR"),
        Link: t("SurveyBlockLink"),
    }

    return {FormBlock, SurveyBlock}
}
