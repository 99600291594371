import React from "react"
import {useTranslation} from "react-i18next"
import styles from "../styles/styles.module.scss"

import {HalfImageHalfText, HalfLinkImageHalfText} from "../../../ui/HalfImage"
import Slider from "../../../components/Slider"
import getContent from "../content/content"

function MVP() {
    const { t } = useTranslation()
    const {IntroBlock, SliderBlockImages, ProductDetails, FunctionalDetails, SurveyDetails, ResearchBlock, HowItWorksBlock, OutreachBlockImages, ContactBlock} = getContent(t)

    return(
        <div className={styles.container}>
            <HalfImageHalfText isImageSmall isImageRight {...IntroBlock}/>
            <Slider images={SliderBlockImages}/>
            <HalfImageHalfText {...HowItWorksBlock}/>
            <HalfImageHalfText isImageRight isFit {...ProductDetails}/>
            <HalfImageHalfText {...FunctionalDetails}/>
            <HalfLinkImageHalfText isImageSmall isImageRight isBlank {...SurveyDetails}/>
            <HalfImageHalfText isFit {...ResearchBlock}/>
            <Slider isFit images={OutreachBlockImages}/>
            {/* Thats server SPA problem - need a devops to fix that
             <HalfLinkImageHalfText isImageRight {...ContactBlock}/> */}
        </div>
    )
}

export default MVP
