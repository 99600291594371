import React from "react"
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {I18nextProvider} from "react-i18next"
import i18n from "./i18n.js"

import './styles/dropping-defaults.scss'
import './styles/base.scss'

import ROUTES from "./constants/ROUTES"
import Navbar from "./modules/Navbar"
import MVP from "./pages/MVP"
import ContactUs from "./pages/ContactUs"
import OurTeam from "./pages/OurTeam"
import {Helmet} from "react-helmet"
function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <Helmet>
                    <title>Cotia</title>
                    <link rel="icon" type="image/png" href="/logo.svg" />
                </Helmet>

                <Navbar/>

                <Routes>
                    <Route exact path={"/"} element={<MVP/>} />
                    <Route exact path={ROUTES.CONTACT_US} element={<ContactUs/>} />
                    <Route exact path={ROUTES.OUR_TEAM} element={<OurTeam/>} />
                </Routes>
            </Router>
        </I18nextProvider>
    )
}

export default App
