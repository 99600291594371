import React from "react"
import styles from "../styles/styles.module.scss"
function HalfImageHalfText({imgName, headerText, subheaderText, descriptionText, paragraphs = [], isImageRight = false, isImageSmall = false, isFit = false}) {
    return(
        <div className={`${styles.container} ${isImageRight && styles.container__imageRight} ${isImageSmall && styles.container__imageSmall}`}>
            <img className={isFit ? styles.img_fit : styles.img} src={imgName} alt={imgName}/>

            <div className={styles.text_container}>
                <p className={styles.header}>{headerText}</p>
                <p className={styles.subheader}>{subheaderText}</p>
                {descriptionText}
                {paragraphs.map((paragraph, index) => (
                    <a key={index} href={paragraph.link} target="_blank" className={styles.link}>
                        <p className={styles.p_center}>{paragraph.text}</p>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default HalfImageHalfText
