import React from "react"
import i18n from "i18next"

const locales = [
    "en", "ua"
]
const languageSwitchers = (styles) => {
    return locales.map(language => (
        <button key={language} onClick={() => i18n.changeLanguage(language)} className={styles}>
            <p style={{fontSize: '2vw'}}>{language == "en" ? "🇬🇧" : "🇺🇦"}</p>
        </button>
    ))
}

export default languageSwitchers
