const burgerStyles = {
    bmBurgerButton: {
        position: 'fixed',
        width: '4vh',
        height: '4vh',
        bottom: 'unset',
        top: '2vh',
        right: '4vh',
        zIndex: "10000"
    },
    bmBurgerBars: {
        background: '#373a47'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        top: "unset",
        left: "unset",
        bottom: "10vh",
        right: "10vw",
        display: "none"
    },
    bmCross: {
        background: 'black'
    },
    bmMenuWrap: {
        top: "0",
        position: 'fixed',
        height: '100%',
        width: "60vw"
    },
    bmMenu: {
        background: "#E8C278",
        padding: '0.5em 1.5em 0',
        fontSize: 'inherit',
        overflow: 'clip'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmOverlay: {
        top: "0",
        background: 'rgba(0, 0, 0, 0.3)'
    }
}

export default burgerStyles
