import React, { useState } from 'react'
import styles from "../styles/styles.module.scss"
import Button from "../../../ui/Button"
function ContactUsForm({headerText, emailPlaceholder, messagePlaceholder, namePlaceholder, submitText, ...props}){
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('email', email)
        formData.append('subject', name)
        formData.append('message', message)

        try {
            const response = await fetch('https://formsubmit.co/e9652f570b54061172d9d43f0bb18042', {
                method: 'POST',
                body: formData,
            })

            if (response.ok) {
                setEmail("")
                setName("")
                setMessage("")
                console.log('Email sent!')
            } else {

                console.log('Error sending email.')
            }
        } catch (error) {
            console.log('Error:', error)
        }
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form} {...props}>
            <h3 className={styles.headerText}>{headerText}</h3>

            <div className={styles.inputs}>
                <input className={styles.input} type="email" placeholder={emailPlaceholder}
                    value={email} onChange={(e) => setEmail(e.target.value)} required />

                <input className={styles.input} type="text" placeholder={namePlaceholder}
                    value={name} onChange={(e) => setName(e.target.value)} required />

                <textarea className={styles.textarea} placeholder={messagePlaceholder}
                    value={message} onChange={(e) => setMessage(e.target.value)} required />
            </div>

            <Button type="submit" text={submitText}/>
        </form>
    )
}

export default ContactUsForm
