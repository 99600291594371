import React from "react"
import VerticalCard from "../../../ui/VerticalCard"
import CircleImageText from "../../../ui/CircleImageText"
import Grid from "../../../components/Grid"
import styles from "../styles/styles.module.scss"
function GridWithCards({items, headerText, isVerticalCards= false, ...props}) {
    // items = Array(8).fill(items[0])

    return(
        <div className={styles.container}>
            <h3 className={styles.header}>{headerText}</h3>
            <Grid {...props}>
                {items.map((item, index) => (
                    isVerticalCards ?
                        <VerticalCard {...item} key={index}/>
                        :
                        <CircleImageText {...item} key={index}/>
                ))}
            </Grid>
        </div>
    )
}

export default GridWithCards
