import React from "react"
import styles from "../styles/styles.module.scss"
function Grid({children, columnsAmount, ...props}) {
    function getGridClassName(){
        let className = styles.grid_4

        switch (columnsAmount){
        case 5:
            className = styles.grid_5
            break
        case 4:
            className = styles.grid_4
            break
        }

        return className
    }

    return(
        <div className={getGridClassName()} {...props}>
            {children}
        </div>
    )
}

export default Grid
