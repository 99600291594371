import React from "react"
import styles from "../styles/styles.module.scss"
function HalfLinkImageHalfText({imgName, link, headerText, subheaderText, descriptionText, isImageRight = false, isImageSmall = false, isBlank = false}) {
    return(
        <div className={`${styles.container} ${isImageRight && styles.container__imageRight} ${isImageSmall && styles.container__imageSmall}`}>
            <a href={link} target={isBlank ? "_blank" : "_self"} rel="noreferrer" className={styles.image_link}>
                <img src={`/${imgName}`} alt={imgName} className={styles.image}/>
            </a>

            <div className={styles.text_container}>
                <p className={styles.header}>{headerText}</p>
                <p className={styles.subheader}>{subheaderText}</p>
                {descriptionText}
            </div>
        </div>
    )
}

export default HalfLinkImageHalfText
