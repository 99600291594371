export default function getContent(t){
    const SliderBlockImages = ["pictures/cats/cat_4.jpg","pictures/cats/cat_2.jpg","pictures/cats/cat_1.jpg","pictures/cats/cat_0.jpg", "pictures/cats/cat_5.jpg", "pictures/cats/cat_6.jpg"]

    const OutreachBlockImages = [
        "pictures/outreach/"+t('Description.outreachImages')+"/_1.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_2.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_3.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_4.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_5.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_6.jpg",
        "pictures/outreach/"+t('Description.outreachImages')+"/_7.jpg",
    ]

    const IntroBlock = {
        imgName: "pictures/cats/cat_3.jpg",
        headerText: t('IntroBlock.headerText'),
        subheaderText: t('IntroBlock.subheaderText'),
        descriptionText: <p>{t('IntroBlock.descriptionText')}</p>
    }
    
    const ProductDetails = {
        imgName: "pictures/mvp/mobile.gif",
        headerText: t('Description.product'),
        descriptionText: <ul>
                            <li>{t('Description.productDetail1')}<br/><br/></li>
                            <li>{t('Description.productDetail2')}<br/><br/></li>
                            <li>{t('Description.productDetail3')}<br/><br/></li>
                            <li>{t('Description.productDetail4')}<br/><br/></li>
                        </ul>
    }

    const FunctionalDetails = {
        imgName: "pictures/mvp/functionality.png",
        descriptionText: <p>{t('Description.functionality1')}<br/><br/>{t('Description.functionality2')}</p>
    }

    const SurveyDetails = {
        imgName: "pictures/QR/Survey.png",
        link: "https://forms.gle/E2JB1AUPT6hLvMKy6",
        headerText: t('Description.help'),
        descriptionText: <p>{t('Description.survey')}</p>
    }

    const ResearchBlock = {
        imgName: "pictures/mvp/researchers.png",
        headerText: t('Description.forecast'),
        descriptionText: <p>{t('Description.research1')}<br/><br/>{t('Description.research2')}<br/><br/>{t('Description.research3')}</p>,
        paragraphs: [{
            link: "https://meowsic.se/catvoc.html",
            text: t('Description.meowsic'),
        }, {
            link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7000907/",
            text: t('Description.communication'),
        }]
    }

    const HowItWorksBlock = {
        imgName: "pictures/mvp/cat_1.jpg",
        headerText: t('Description.workHeader'),
        descriptionText: <ul>
                            <li>{t('Description.howItWorks1')}<br/><br/></li>
                            <li>{t('Description.howItWorks2')}<br/><br/></li>
                            <li>{t('Description.howItWorks3')}<br/><br/></li>
                        </ul>
    }

    const ContactBlock = {
        imgName: t('Description.contactLink'),
        link: "/contact-us",
        descriptionText: <p>{t('Description.contact')}</p>
    }

    return {IntroBlock, SliderBlockImages, ProductDetails, FunctionalDetails, SurveyDetails, ResearchBlock, HowItWorksBlock, OutreachBlockImages, ContactBlock}
}
