function getSocialMedias(instagramLink, linkedinLink, telegramLink){
    return [
        {link: instagramLink, iconName: "pictures/icons/instagram.svg"},
        {link: linkedinLink, iconName: "pictures/icons/linkedin.svg"},
        {link: telegramLink, iconName: "pictures/icons/telegram.svg"}
    ]
}
export default function getContent(t){
    const TeamBlockItems = [
        {
            imgName: "pictures/team/AndriiMykhailiv.jpg",
            headerText: t("TeamBlockItems.AndriiMykhailiv"),
            professionText: t("TeamBlockItems.professionAndriiMykhailiv"),
            socialMedias: getSocialMedias("https://instagram.com",
                "https://google.com", "https://tm.me/Oleh_Kril")
        },
        {
            imgName: "pictures/team/SofiiaSubtelna.jpg",
            headerText: t("TeamBlockItems.SofiiaSubtelna"),
            professionText: t("TeamBlockItems.professionSofiiaSubtelna"),
            socialMedias: getSocialMedias("https://instagram.com",
                "https://google.com", "https://tm.me/Oleh_Kril")
        },
        {
            imgName: "pictures/team/IraIskovych.jpg",
            headerText: t("TeamBlockItems.IraIskovych"),
            professionText: t("TeamBlockItems.professionIraIskovych"),
            socialMedias: getSocialMedias("https://instagram.com",
                "https://google.com", "https://tm.me/Oleh_Kril")
        },
        {
            imgName: "pictures/team/MaksymPavlish.jpg",
            headerText: t("TeamBlockItems.MaksymPavlish"),
            professionText: t("TeamBlockItems.professionMaksymPavlish"),
            socialMedias: getSocialMedias("https://instagram.com",
                "https://google.com", "https://tm.me/Oleh_Kril")
        },
        {
            imgName: "pictures/team/OlehBahan.jpg",
            headerText: t("TeamBlockItems.OlehBahan"),
            professionText: t("TeamBlockItems.professionOlehBahan"),
            socialMedias: getSocialMedias("https://instagram.com",
                "https://google.com", "https://tm.me/Oleh_Kril")
        },
    ]
    const TeamBlockHeaderText = t("TeamBlockHeaderText")

    const AchievementBlockItems = [
        {
            imgName: "pictures/achievement/venture.png",
            headerText: t("AchievementsBlockItems.invest"),
            descriptionText: t("AchievementsBlockItems.invsetDescriptionText")
        },
        {
            imgName: "pictures/achievement/cig.jpg",
            headerText: "CIG R&D Lab",
            descriptionText: t("AchievementsBlockItems.cigDescriptionText")
        },
        {
            imgName: "pictures/achievement/yep.png",
            headerText: t("Yep!"),
            descriptionText: t("AchievementsBlockItems.yepDescriptionText")
        }
    ]
    const AchievementBlockHeaderText = t('AchievementBlockHeaderText')

    const PartnersBlockItems = [
        {
            imgName: "pictures/partners/cig.jpg",
            headerText: "CIG R&D",
            descriptionText: t("PartnersBlockItems.cigDescriptionText")
        },
        {
            imgName: "pictures/partners/techStartUpSchool.png",
            headerText: "TechStartupSchool",
            descriptionText: t("PartnersBlockItems.techstartupschoolDescriptionText")
        },
        {
            imgName: "pictures/partners/AI.png",
            headerText: t("PartnersBlockItems.ai"),
            descriptionText: t("PartnersBlockItems.aiDescriptionText")
        },
        {
            imgName: "pictures/partners/UWE.jpg",
            headerText: "UWE",
            descriptionText: t("PartnersBlockItems.uweDescriptionText")
        },
        {
            imgName: "pictures/partners/startUpWiseGuys.png",
            headerText: "Startup Wise Guys",
            descriptionText: t("PartnersBlockItems.startapwiseguysDescriptionText")
        },
        {
            imgName: "pictures/partners/eit.jpg",
            headerText: "EIT Jumpstarter",
            descriptionText: t("PartnersBlockItems.eitDescriptionText")
        },
    ]
    const PartnersBlockHeaderText = t('PartnersBlockHeaderText')

    return {TeamBlockItems, TeamBlockHeaderText, AchievementBlockItems, AchievementBlockHeaderText, PartnersBlockItems, PartnersBlockHeaderText}
}
