import React from "react"
import ROUTES from "../../../constants/ROUTES"
import { Link } from 'react-router-dom'

function getLinks(translate, className, onClick){
    const links = []

    Object.entries(ROUTES).map(([text, path], idx) => {
        const link =
            <Link key={idx} to={`${path}`} onClick={onClick} className={className}>
                {translate([text])}
            </Link>

        links.push(link)
    })

    return links
}


export default getLinks
