import React from "react"
import NavbarMobile from "./NavbarMobile"
import NavbarDesktop from "./NavbarDesktop"

function Navbar(){
    return(
        <>
            <NavbarDesktop/>
            <NavbarMobile/>
        </>
    )
}
export default Navbar
