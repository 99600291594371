import React from "react"
import styles from "../styles/styles.module.scss"
function VerticalCard({imgName, headerText, professionText, socialMedias }) {
    function getSocialMediaIcons(socialMedias){
        return socialMedias.map((socialMedia, idx) =>
            <a href={socialMedia.link} target="_blank" rel="noreferrer" key={idx}>
                <img src={`/${socialMedia.iconName}`} alt={socialMedia.iconName}/>
            </a>
        )
    }
    return(
        <div className={styles.container}>
            <img className={styles.image} src={imgName} alt={imgName} />
            <h3 className={styles.header}>{headerText}</h3>

            <p className={styles.description}>{professionText}</p>
            <div className={styles.icons_container}>
                {getSocialMediaIcons(socialMedias)}
            </div>
        </div>
    )
}

export default VerticalCard
