import React from "react"
import styles from "../styles/styles.module.scss"

function Button({text, ...props}) {
    return(
        <button className={styles.button} {...props}>{text}</button>
    )
}

export default Button
