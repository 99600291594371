import React from "react"
import {useState} from "react"
import {slide as Menu} from "react-burger-menu"
import burgerStyles from "../styles/BurgerLibraryStyles"
import styles from "../styles/NavbarMobile.module.scss"
import {useTranslation} from "react-i18next"
import getLanguageSwitchers from "../helpers/getLanguageSwitchers"
import getLinks from "../helpers/getLinks"


function NavbarMobile() {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const links = getLinks(t, styles.options, () => setIsOpen(false))
    const languageSwitchers = getLanguageSwitchers(styles.language)

    return (
        <div className={styles.container}>
            <Menu right styles={burgerStyles}
                isOpen={isOpen}
                onStateChange={(state) => setIsOpen(state.isOpen)}>

                <div className={styles.burger}>
                    {languageSwitchers}
                    {links}
                </div>

            </Menu>
        </div>
    )
}

export default NavbarMobile
