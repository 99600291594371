import React from "react"
import styles from "../styles/styles.module.scss"
import ContactUsForm from "../../../modules/ContactUsForm"
import getContent from "../content/content"
import {useTranslation} from "react-i18next"
import LinkImage from "../../../components/LinkImage"
function ContactUs() {
    const { t } = useTranslation()
    const {FormBlock, SurveyBlock} = getContent(t)

    return(
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.sub_container}>
                    <LinkImage headerText={SurveyBlock.HeaderText} imageName={SurveyBlock.QR} link={SurveyBlock.Link}/>
                </div>
                <ContactUsForm headerText={FormBlock.HeaderText} emailPlaceholder={FormBlock.EmailPlaceholder}
                    namePlaceholder={FormBlock.NamePlaceholder} messagePlaceholder={FormBlock.MessagePlaceholder}
                    submitText={FormBlock.Button}/>
            </div>
        </div>
    )
}

export default ContactUs
