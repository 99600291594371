import React from "react"
import styles from "../styles/styles.module.scss"
import {useTranslation} from "react-i18next"
import getContent from "../content/content"
import GridWithCards from "../../../modules/GridWithCards"
function OurTeam() {
    const { t } = useTranslation()
    const {TeamBlockItems, TeamBlockHeaderText, AchievementBlockItems, AchievementBlockHeaderText, PartnersBlockItems, PartnersBlockHeaderText} = getContent(t)

    return(
        <div className={styles.container}>
            <GridWithCards items={TeamBlockItems} columnsAmount={5} headerText={TeamBlockHeaderText} isVerticalCards/>
            <GridWithCards items={AchievementBlockItems} columnsAmount={3} headerText={AchievementBlockHeaderText}/>
            <GridWithCards items={PartnersBlockItems} columnsAmount={4} headerText={PartnersBlockHeaderText}/>
        </div>
    )
}

export default OurTeam
