import React from "react"
import styles from "../styles/NavbarDesktop.module.scss"
import {useTranslation} from "react-i18next"
import getLinks from "../helpers/getLinks"
import getLanguageSwitchers from "../helpers/getLanguageSwitchers"
import {Link} from "react-router-dom"

function NavbarDesktop() {
    const { t } = useTranslation()

    const links = getLinks(t, styles.options)
    const languageSwitchers = getLanguageSwitchers(styles.options)

    return (
        <div className={styles.navbar}>
            <Link to={"/"} className={styles.logo}>
                <img src="./logo.svg" alt="logo"/>
            </Link>

            <div className={styles.options_container}>
                <div className={styles.sub_container}>
                    {languageSwitchers}
                </div>

                <div className={styles.sub_container}>
                    {links}
                </div>
            </div>
        </div>

    )
}
export default NavbarDesktop
