import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const getAllTranslationsContex = () => require.context('./', true, /[A-Z][a-zA-Z]*\/locales\/(en|ua)\.json$/)
function combineAllTranslations(requireContext){
    const translations = {}

    requireContext.keys().forEach((key) => {
        const lang = key.match(/([a-z]{2})\.json$/i)[1]
        const translationObj = requireContext(key)

        if (!translations[lang]) {
            translations[lang] = translationObj
        } else {
            translations[lang].translation = {
                ...translations[lang].translation,
                ...translationObj.translation
            }
        }
    })
    console.log(translations)
    return translations
}
function loadTranslations() {
    const translationsContex = getAllTranslationsContex()

    return combineAllTranslations(translationsContex)
}


const translations = loadTranslations()

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('languageDefault') || 'en',
        debug: true, // Set this to false in production
        interpolation: {
            escapeValue: false
        },
        resources: translations
    })

export default i18n



