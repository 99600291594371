import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/scss/navigation'
import '../styles/swiper-custom-arrows.scss'

import styles from "../styles/styles.module.scss"

SwiperCore.use([Navigation])
function Slider({images, isFit = false}){

    const breakpoints = {
        900: {
            slidesPerView: 2,
            spaceBetween: "-100"
        },
    }

    return (
        <div className={styles.container}>
            <Swiper
                slidesPerView={1}
                breakpoints={breakpoints}
                loop={true}
                centeredSlides={true}
                navigation
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img className={isFit ? styles.image_fit : styles.image_slide} src={`/${image}`} alt={image}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Slider
