import React from "react"
import styles from "../styles/styles.module.scss"
function CircleImageText({imgName, headerText, descriptionText }) {
    return(
        <div className={styles.container}>
            <img className={styles.image} src={imgName} alt={imgName} />
            <h3 className={styles.header}>{headerText}</h3>
            <p className={styles.description}>{descriptionText}</p>
        </div>
    )
}

export default CircleImageText
